import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {WillAnimate} from '../../components/content_animate';
import {Layout} from '../../components/layout';
import {ScrollTo} from '../../components/scroll_to';

export default ({data}: any) => (
    <Layout
        title="Contact – Enschede – Samen softwareproducten creëren?"
        description="Nieuwe softwareproductontwikkelingsopdracht? Neem contact op met ons kantoor in Enschede. Ook als je een stap wilt maken in je carrière horen we graag van je."
        ogImage="contact.png"
        translatedPagePath="/en/contact/">
        <div id="hero" className="hero hero--contact">
            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h2>
                        Nieuwe <span className="highlight">opdracht</span>? E-mail ons via{' '}
                        <a href="mailto:hi@305.nl">hi@305.nl</a> <br />
                        <br />
                        Stap in je <span className="highlight">carrière</span>? Bekijk onze{' '}
                        <a
                            title="Carrière website (opent in een nieuwe tab)"
                            target="_blank"
                            href="https://careers.305.nl/?lang=nl"
                            rel="noreferrer">
                            vacatures en stages
                        </a>
                    </h2>
                </div>
            </div>
            <div className="hero__locations-wrapper">
                <div className="hero__column">
                    <ScrollTo
                        target="/nl/contact/#enschede"
                        className="hero__location hero__location--inline"
                        title="Contactgegevens Enschede">
                        Enschede
                    </ScrollTo>
                    <ul className="list">
                        <li className="list__item">
                            <a
                                title="Google Maps"
                                href="https://www.google.com/maps/place/Label305+Enschede/@52.2247088,6.8829631,17z/data=!3m1!4b1!4m5!3m4!1s0x47b813da389ae993:0x2b88f1b9f0b67b68!8m2!3d52.2247469!4d6.8851907">
                                Hengelosestraat 86 <br /> 7514AK Enschede
                            </a>
                        </li>
                        <li className="list__item">
                            <a title="E-mail ons" href="mailto:hi@305.nl">
                                hi@305.nl
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <ScrollTo className="hero__scroll" target="/nl/contact/#enschede" position="start" />
        </div>

        <section className="section">
            <WillAnimate as="article" id="enschede" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image content__image--wide">
                            <div className="image">
                                <div className="image__content">
                                    <Img alt="Kantoor Enschede" fluid={data.enschede.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>
                        <div className="content__copy content__copy--wide">
                            <h2>Enschede</h2>
                            <p>
                                Ons kantoor is gevestigd op de begane grond van een mooi authentiek pand aan de
                                Hengelosestraat.
                            </p>
                            <p>
                                Neem afslag Enschede-West op de A35 en rijd bij de eerste stoplichten rechtdoor. Ga bij
                                de tweede stoplichten linksaf en volg de borden "centrum". Blijf de borden volgen totdat
                                je niet meer rechtdoor kan en sla hier linksaf, richting de tunnel onder het spoor. Volg
                                de weg met de bocht naar links en je ziet ons kantoor verschijnen aan de rechterkant.
                            </p>
                            <p>
                                Kom je met het OV? Stap dan uit op treinstation Enschede en neem de bus (of loop) naar
                                bushalte Enschede Schuttersveld.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>
        </section>
    </Layout>
);

export const query = graphql`
    query {
        enschede: file(relativePath: {eq: "office/hengelosestraat.png"}) {
            ...fluid1600Width
        }
    }
`;
